#menubutton {
    .icon {
        @include w100bl;
        transition: color 0.1s;

        color:$brown;
        body.desktopmenuvisible & {
            color:$white;
        }
        body.ws16 & { //Home
            color:$white;
        }
    }
    position: fixed;
    @include rem-calc-sl(right, 45, 0.5);
    @include rem-calc-sl(top, 41, 0.5);
    @include rem-calc-sl(width, 46, 0.75);
    z-index: 100;
    cursor: pointer;
}
