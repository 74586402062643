.bl-linkboxes {
    .items {
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        @include rem-calc-sl(gap, 200, 0.5);

        @include breakpoint(medium down) {
            grid-template-columns:repeat(1, 1fr);
        }    
    }
    .item {
        display: block;
        .ctrimage {
            width:60%;
            img {
                @include w100bl;
                @include borderradius1;
            }
        }
        .ctrinfo {
            margin-top:1em;
        }
        .ctrbutton {
            margin-top:2em;
        }
    }
}
