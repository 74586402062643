$black:#000;
$white:#fff;
$brown:#827332;
$textcolor:#3E3B0E;
$footertext:#827332;
$bluetext:#378AB4;

$brownbg: #AA9D41;
$bluebg: #378AB4;

@mixin borderradius1 {
    //images
    border-radius: 2rem;
}
@mixin borderradius2 {
    //button
    border-radius: 0.5rem;
}
$buttonbg:#827332;
$buttonbg-hover:#AA9D41;

body {
    --gap1:2rem;
    --gap2:4rem;
}