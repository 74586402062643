.bl-imgbgquote {
    position: relative;

    .ctrimage {
        position: absolute;
        inset:0;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    .ctrquote {
        @include rem-calc-sl(padding-top, 180, 0.5);
        @include rem-calc-sl(padding-bottom, 180, 0.5);
    }
    .quote {
        position: relative;

        max-width: rem-calc(550);

        @include font-size-pt(70*0.75, 70);
        color:$white;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: math.div(94, 70);
        
        padding-bottom:0.25em;
        border-bottom:8px solid $white;        
    }
}