.bl-quote {
    .quote {
        @include typo-biglead;
        color:$bluetext;
    }
    .author {
        font-size:math.div(18,24)*1em;
        margin-top: 0.5em;
    }
}
