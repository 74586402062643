.bl-teamfeat {
    @include breakpoint(large) {
        display: flex;
        gap:var(--gap1);

        .ctrtext {
            margin-top: 2em;
        }
    }
    .ctrimage {
        img {
            @include w100bl;
            border-radius: 100%;
        }
        width:10rem;
    }
    @include breakpoint(medium down) {
        .ctrtext {
            margin-top: 1em;
        }            
    }
}

