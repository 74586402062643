header {
  @include rem-calc-sl(padding-top, 20, 0.5);
  @include rem-calc-sl(padding-bottom, 20, 0.5);

  .ctrlogo {
    display: block;
      .ctx-home & {
        @include rem-calc-sl(width, 112, 0.75);
      }
      .ctx-web & {
        @include rem-calc-sl(width, 272, 0.75);
      }
      .ctx-langlauf & {
        @include rem-calc-sl(width, 300, 0.75);
      }
      img {
          @include w100bl;
      }
  }
}