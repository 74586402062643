@font-face {
    font-display: swap;
    font-family: 'Neo';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/neo-sans-std-regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Neo';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/neo-sans-std-light.woff') format('woff');
}
