footer {
  .flexh {
    gap:var(--gap2);
  }
  color:$footertext;
  @include rem-calc-sl(padding-top, 85, 0.5);
  @include rem-calc-sl(padding-bottom, 100, 0.5);
  .logo {
      @include w100bl;
      .ctx-home & {
        @include rem-calc-sl(width, 186, 0.75);
      }
      .ctx-web & {
        @include rem-calc-sl(width, 272, 0.75);
      }
      .ctx-langlauf & {
        @include rem-calc-sl(width, 300, 0.75);
        @include rem-calc-sl(margin-top, -30, 0.5);
      }
  }
  .socialicons {
    display:flex;
    gap:0.75em;
    margin-top:0.4em;
  }
  .icon {
    height: 1.25em;
  }

  @include breakpoint(medium down) {
    address, .socialicons {
      margin-top: 1em;
    }
    .rlinks {
      margin-top: 0.75em;
    }
  }
}

footer {
  letter-spacing: 0.05em;
  strong {
    font-weight: 400;
  }
  .rlinks {
    font-weight: 400;
  }
}
