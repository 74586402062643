.bl-team {
    .items {
    }

    .item {
        @include breakpoint(large) {
            display: grid;
            grid-template-columns: 3fr 4fr;
            gap:var(--gap1);

            &:nth-child(even) {
                grid-template-columns: 4fr 3fr;
                .ctrimage {
                    order:2;
                }
            }
        }
        .ctrimage {
            img {
                @include w100bl;
                @include borderradius1;
            }
        }
    }

    .item + .item {
        margin-top:4em;
    }           

    @include breakpoint(medium down) {
        .ctrtext {
            margin-top:1.5em;
        }
    }
}