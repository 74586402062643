.bl-colorbox {
    padding:2em 2em;
    color:white;

    @include borderradius1;

    &.color-blue {
        background-color: #378AB4;
    }
    &.color-brown {
        background-color: #AA9D41;
    }
}

.bl-colorbox {
    h4 {
        margin-bottom: 1em;
    }
}