.buttonbar {

}
.button {
  display: block;
  width:fit-content;
  padding:0.5em 2em;
  color:white;
  @include borderradius2;
  background-color: $buttonbg;

  @include font-size-pt(20*0.75, 20);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: math.div(25, 20);
}

.button {
  transition: background-color 0.2s;

  &:hover {
      background-color: $buttonbg-hover;
  }
}
