.bl-aboutme {
    @include breakpoint(large) {
        display:grid;
        grid-template-columns:2fr 3fr;
        gap:var(--gap2);
        align-items: center;
    }

    .ctrimage {
        img {
            @include w100bl;
            @include borderradius1;
        }
    }

    .ctrinfo {
        .text {
            @include typo-lead;
            margin-top: 1em;
        }
    }

    @include breakpoint(medium down) {
        .ctrinfo {
            margin-top:1.5em;
        }
    }
}