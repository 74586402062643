#desktopmenu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    z-index: 50;

    color:white;

    display:flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding:10vw;
    overflow: auto;

    .cols {
        display: grid;
        width: 100%;
        height:100%;

        justify-content: center;    
        align-content: center;    
    }

    .col1, .col2 {
        min-width: 0;
        min-height: 0;
    }

    .menuitems {
        ul {
            @include reset-list;
        }
    }

    .ctrlogo {
        //ausgeblendet am 5.12.2023
        position: absolute;
        @include rem-calc-sl(left, 45, 0.5);
        @include rem-calc-sl(top, 41, 0.5);
        @include rem-calc-sl(width, 58, 1);

        img {
            @include w100bl;
        }
    }

    @media (orientation:landscape) {
        background: linear-gradient(90deg, $brownbg, $brownbg 50%, $bluebg 50%, $bluebg);

        .cols {
            grid-template-columns: 1fr 1fr;
        }
        .col1 {
            justify-self: end;
            .menuitems {
                @include rem-calc-sl(padding-right, 130, 0.5);
            }
        }
        .col2 {
            justify-self: start;
            .menuitems {
                @include rem-calc-sl(padding-left, 130, 0.5);
            }
        }
    }
    @media (orientation:portrait) {
        background: linear-gradient(0deg, $brownbg, $brownbg 50%, $bluebg 50%, $bluebg);

        .cols {
            grid-template-columns: auto;
            grid-template-rows: 1fr 1fr;
        }
        .col1 {
            align-self: end;
            .menuitems {
                @include rem-calc-sl(padding-bottom, 50, 0.5);
            }
        }
        .col2 {
            align-self: start;
            .menuitems {
                @include rem-calc-sl(padding-top, 50, 0.5);
            }
        }
    }
}

#desktopmenu {
    h1 {
        color:inherit;
        @include font-size-pt(70*0.5, 70);
    }
    .menuitems {
        @include font-size-pt(40*0.6, 40);
        font-weight: 400;
        letter-spacing: 0em;
        line-height: math.div(70, 40);
    }
}

#desktopmenu {
    transform: translateY(-100%);
    transition:transform 0.3s;

    &.visible {
        transform: none;
    }
}