//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  font-weight: 300;
  @include font-size-pt(24*0.75, 24);
  letter-spacing: 0.01em;
  line-height:math.div(32, 24);
  @include font-size-pt(20, 26);
  color:$textcolor;
}

body {
  font-family:'Neo', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  @include font-size-pt(50*0.75, 50);
  color:$bluetext;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(67, 50);
}
@mixin typo-h2 {
  @include font-size-pt(38*0.75, 38);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(51, 38);
}
@mixin typo-h3 {
  @include font-size-pt(32*0.75, 32);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(43, 32);
}
@mixin typo-h4 {
  font-size: inherit;
  font-weight: 400;
  letter-spacing: 0.01em;
}

@mixin typo-lead {
  @include font-size-pt(34*0.75, 34);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(46, 34);
}
@mixin typo-biglead {
  font-family: 'Yeseva One', serif;
  @include font-size-pt(45*0.75, 45);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(60, 45);
}

strong {
  font-weight: 400;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}
p a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

h1, h2 {
  margin:0 0 0.75em 0;
}
h3 {
  margin:0 0 0.5em 0;
}
h4 {
  margin:0 0 0.25em 0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
}