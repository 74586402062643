.bl-ctteaser {
    .colimage img {
        @include w100bl;
        @include borderradius1;
    }
    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:var(--gap1);
            align-items: center;
        }
        &.imgpos-l {
            .coltext {
                order: 2;
                padding-left: 2em;
            }
        }
        &.imgpos-r {
            .coltext {
                padding-right: 2em;
            }
        }
    }
    .ctrinfo {
        .text {
            @include typo-lead;
            font-weight: 300;
        }
        .button {
            margin-top: 2em;
        }
    }
    @include breakpoint(medium down) {
        .colimage {
            margin-top: 2em;
        }
    }
}