.bl-herohome {
    .ctrimages {
        height: 100vh;
        height: 100lvh;

        display:grid;
        @media (orientation:landscape) {
            grid-template-columns: 1fr 1fr;
        }
        @media (orientation:portrait) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }
    .ctrimage1, .ctrimage2 {
        width:100%;
        height: 100%;
        position: relative;

        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
        .ctoverlay {
            @include absposfull;

            display:flex;
            justify-content: center;
            align-items: flex-end;
            .text {
                @media (orientation:landscape) {
                    margin-bottom:20vh;
                }
                @media (orientation:portrait) {
                    margin-bottom:20vh;
                }

                @include font-size-pt(70*0.75, 70);
                color:$white;
                font-weight: 400;
                letter-spacing: 0em;
                line-height: math.div(94, 70);
            }
        }
    }
    .ctrimage1 .ctoverlay {
        background-color: rgba(#AA9D41, 0.5);
    }
    .ctrimage2 .ctoverlay {
        background-color: rgba(#378AB4, 0.5);
    }
    .logooverlay {
        pointer-events: none;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include borderradius1;

        background-color: white;

        img {
            display: block;
            //@include rem-calc-sl(width, 300, 0.5);
            width:clamp(#{rem-calc(100)}, 20vw, #{rem-calc(300)});
            //max-width: 20vw;
        }
        @include rem-calc-sl(padding, 40, 0.5);
    }
}

.bl-herohome {
    .ctoverlay {
        opacity: 0;
        transition: opacity 0.2s;
        &:hover {
            opacity: 1;
        }
    }

    .ctoverlay .text {
        transform: translateY(50%);
        transition: transform 0.4s;
    }
    .ctoverlay:hover .text {
        transform: translateY(0);
    }
}