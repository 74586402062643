body {
  overflow-x: hidden;

  margin:0;
  padding:0;
}


body.bgcolor-yellow {
  --sitebgcolor:#FAF9EF;
}
body.bgcolor-blue {
  --sitebgcolor:#E5F2F8;
}
header, main {
  background-color:var(--sitebgcolor);
}
