.bl-textwithimage {
    .colimage img {
        @include w100bl;
        @include borderradius1;
    }
    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: 11.5fr 15.5fr;
            gap:var(--gap1);
        }
        .coltext {
            z-index: 1;
            margin-top: 2em;
        }

        &.overlap1 {
            .coltext {
                margin-right: -5em;
            }
        }
    }
    @include breakpoint(medium down) {
        .colimage {
            margin-top: 2em;
        }
    }
}

.bl-textwithimage {
    &.overlap0 .lead {
        @include typo-lead;
    }
    &.overlap1 .lead {
        @include typo-biglead;
        text-shadow: 1px 1px 0 var(--sitebgcolor),
            1px -1px 0 var(--sitebgcolor), 
            -1px 1px 0 var(--sitebgcolor), 
            -1px -1px 0 var(--sitebgcolor);
    }
}