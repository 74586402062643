.bl-richtext {
    &.lead1 {
        @include typo-lead;
    }

    &.dashlist {
        ul {
            @include reset-list;
        }
        li:before {
            //reset default bullet
            display: none;
        }

        li {
            border-top:2px dashed currentColor;
            padding:0.4em 0 0.2em 0;
        }
        ul {
            border-bottom:2px dashed currentColor;
            font-weight: 300;
        }
    }
}